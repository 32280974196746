<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small> View {{ myTitle }} </small>
    <hr />
    <div class="row">
      <div class="col-sm-6">
        <Balance title="Coins for Students" :point="company.coin" />
      </div>
      <div class="col-sm-6">
        <Pro
          title="Annual Pro Accounts for Students"
          :point="company.pro_quantity"
        />
      </div>
    </div>
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table class="mt-4" :data="teachers" style="min-width: 100%" border>
      <el-table-column label="Name">
        <template slot-scope="scope">
          <template>
            <router-link
              :to="{
                name: 'CompanyAdminViewUser',
                params: {
                  id: scope.row.id
                }
              }"
            >
              <b>
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </b>
            </router-link>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email" width="350">
        <template slot-scope="scope">
          <b class="text-info">{{ scope.row.email }}</b>
        </template>
      </el-table-column>
      <el-table-column label="Balance" width="140">
        <template slot-scope="scope">
          <h5 class="text-success">
            {{ scope.row.coin }}
            <small v-if="scope.row.coin > 1">Coins</small>
            <small v-else-if="scope.row.coin >= 0 && scope.row.coin < 2">
              Coin
            </small>
            <el-button
              class="ml-2"
              type="text"
              v-show="company.coin > 0"
              @click="selectStudent(scope.row)"
            >
              <i class="fa fa-plus"></i>
            </el-button>
          </h5>
        </template>
      </el-table-column>
      <el-table-column label="Plan" width="120">
        <template slot-scope="scope">
          <b
            v-if="(scope.row.all_roles || []).includes('pro')"
            style="color: orange;"
          >
            <span class="proIcon">
              <i class="fas fa-crown" />
              Pro
            </span>
          </b>
          <b v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="Click to Upgrade 12 Months Pro"
              placement="top"
              v-if="company.pro_quantity > 0"
            >
              <el-button
                type="info"
                @click="assignYearlyPro(scope.row.id)"
                size="small"
              >
                Free
              </el-button>
            </el-tooltip>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Role" width="200">
        <template slot-scope="scope">
          <span v-for="(role, index) in scope.row.all_roles" :key="index">
            <el-tooltip
              class="item"
              effect="dark"
              content="Admin"
              placement="top"
            >
              <el-tag
                v-if="role === 'company-admin'"
                type="success"
                class="mr-2"
              >
                <i class="fas fas fa-building"></i>
              </el-tag>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Teacher"
              placement="top"
            >
              <el-tag v-if="role === 'teacher'" type="success" class="mr-2">
                <i class="fas fa-chalkboard-teacher"></i>
              </el-tag>
            </el-tooltip>
          </span>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Student"
            placement="top"
          >
            <el-tag type="success" class="mr-2">
              <i class="fas fa-user"></i>
            </el-tag>
          </el-tooltip> -->
          <el-button type="text" @click="selectRole(scope.row)">
            <i class="fa fa-edit"></i>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Date Registered" width="150">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.last_ip">
            <template v-if="scope.row.last_iso">
              ({{ scope.row.last_iso }})
            </template>
            {{ scope.row.last_ip }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Action" width="80">
        <template slot-scope="scope">
          <!-- <el-tooltip effect="dark" content="Logs" placement="top">
            <router-link
              :to="{
                name: 'AdminUsersLogs',
                query: {
                  search: scope.row.email
                }
              }"
            >
              <el-button class="mr-2" type="success" size="small">
                <i class="fa fa-eye"></i>
              </el-button>
            </router-link>
          </el-tooltip> -->
          <el-tooltip effect="dark" content="Remove" placement="top">
            <el-button
              type="danger"
              size="small"
              @click="removeStudent(scope.row.id)"
            >
              <i class="fas fa-user-minus"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Add Balance"
      v-if="student"
      :visible.sync="showStudent"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="User">
          <b>
            {{ user.displayName(student.first_name, student.last_name) }} ({{
              student.email
            }})
          </b>
        </el-form-item>
        <el-form-item label="Balance">
          <b class="text-success">
            {{ form.oCoins }}
            <small v-if="form.oCoins > 1">Coins</small>
            <small v-else-if="form.oCoins > 0 && form.oCoins < 2">
              Coin
            </small>
          </b>
        </el-form-item>
        <!-- <el-form-item label="Type">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">Deposit</el-radio-button>
            <el-radio-button :label="0">Deduction</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="">
          <el-input-number
            v-model="form.coins"
            :min="0"
            :max="company.coin"
            :step="10"
            style="width: 100%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button
              type="success"
              :disabled="form.coins > company.coin"
              @click="giveCoins"
            >
              Add
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Edit Role"
      v-if="activeUser"
      :visible.sync="showRole"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="User">
          <b>
            {{ user.displayName(activeUser.first_name, activeUser.last_name) }}
            ({{ activeUser.email }})
          </b>
        </el-form-item>
        <el-form-item label="Select Role">
          <el-checkbox v-model="isTeacher">
            <i class="fas fa-chalkboard-teacher"></i>
            Teacher
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button type="success" @click="saveRoles">
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import SAT from "@/apis/sat";
import User from "@/apis/user";
import { user, instant } from "@ivy-way/material";
import Balance from "@/components/balance/Balance.vue";
import Pro from "@/components/balance/Pro.vue";

export default {
  metaInfo() {
    return {
      title: ` ${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    Balance,
    Pro
  },

  mixins: [],

  props: [],
  data() {
    return {
      teachers: [],
      isTeacher: false,
      activeUser: null,
      company: null,
      companies: null,
      showCompanyModal: false,
      showStudent: false,
      student: null,
      form: {
        uid: null,
        oCoins: 0,
        coins: 0,
        type: 1,
        all_roles: []
      },
      resultsTotal: null,
      showRole: false,
      pageSize: 10
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    myTitle() {
      return "Users";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    companyId() {
      return this.getProfile.company_id;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {
    companyId() {
      this.getUsers();
    }
  },

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    // this.searchTeachers();
    this.getUsers();
  },

  methods: {
    removeStudent(userId) {
      this.$confirm(
        "Are you sure you want to delete this person? This cannot be undone.",
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await User.CompanyRemoveUser(userId);
        this.getUsers();
      });
    },
    async giveCoins() {
      await Company.companyGiveCoins(this.form.uid, {
        coins: this.form.coins
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.getUsers();
      this.showStudent = false;
    },
    assignYearlyPro(id) {
      this.$confirm("Upgrade 12 Months Pro?", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async () => {
        await Company.companyGivePro(id);
        await this.getUsers();
        await this.$message({
          type: "success",
          message: "Success!"
        });
      });
    },
    selectStudent(student) {
      this.student = null;
      this.student = student;
      this.showStudent = true;
      this.form.uid = student.id;
      this.form.coins = 0;
      this.form.oCoins = student.coin;
      this.form.all_roles = student.all_roles;
    },
    async getUsers(query) {
      const students = await Company.getUsers({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name"
      });
      this.company = students.company;
      this.teachers = students.users.data;
      this.resultsTotal = students.users.total;
      this.pageSize = students.users.per_page;
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    selectRole(user) {
      this.activeUser = null;
      this.activeUser = user;
      this.showRole = true;
      if (user.all_roles.indexOf("teacher") > -1) {
        this.isTeacher = true;
      } else {
        this.isTeacher = false;
      }
    },
    async saveRoles() {
      await Company.companySetTeacher({
        user_id: this.activeUser.id,
        assign_teacher: this.isTeacher
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      // window.location.reload();
      this.getUsers();
      this.showRole = false;
      // this.isTeacher = false;
    }
  }
};
</script>

<style scoped></style>
